import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App dark">
      <center><h1><span className='muted'>The</span>P4nda.at</h1></center>
    </div>
  );
}

export default App;

